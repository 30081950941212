export default {
    header: {
        login: 'Intră',
        balance: 'Balanță'
    },
    banners: {
        firstDeposit: {
            title: 'Bonus pentru jucători noi'
        },
        wheel: {
            title: 'Roata norocului'
        },
        cashback: {
            title: 'Вернём на счёт'
        }

    },
    index: {
        getBonusButton: 'Activează bonusul',
        gamesHeader: 'Jocuri',
        winnersHeader: 'Eroii zilei',
        showMore: 'Afișați mai multe',
        favorite: 'Favorite',
        lastGames: 'Ultimele jocuri',
    },
    menu: {
        main: 'Pagina principală',
        promo: 'Promoții',
        profile: 'Profilul meu',
        history: 'Istoric',
        deposit: 'Suplimentare cont',
        payout: 'Cash out',
        logout: 'Ieși din cont',
        entrance: 'Conectare / Înregistrare',
        licence: 'Licență',
        faq: 'FAQ (ajutor)'
    },
    login: {
        header: 'Вход',
        phoneInput: {
            label: 'Număr de telefon',
            placeholder: 'Telefon',
            required: 'Укажите номер целиком',
        },
        passwordInput: {
            label: 'Parola ta',
            placeholder: 'Parola',
            required: 'Ai uitat parola?'
        },
        forgotPasswordLink: 'Ai uitat parola?',
        invalidCredentials: 'Parolă incorectă',
        restorePasswordButton: 'Restabilire parolă',
        loginButton: 'Intră',
        registerLinkHeading: 'Încă nu ai un profil?',
        registerLink: 'Înregistrare',
    },
    account: {
        title: {
            real: 'Основной',
            main: 'Основной',
            bonus: 'Бонусный',
        }
    },
    profile: {
        header: 'Профиль',
        passwordForm: {
            header: 'Schimbă parola',
            currentPassword: {
                label: 'Introdu parola veche',
                placeholder: 'Старый пароль',
                required: 'укажите текущий пароль',
                invalid: 'неверный текущий пароль'
            },
            newPassword: {
                label: 'Introdu parola nouă',
                placeholder: 'Новый пароль',
                required: 'укажите новый пароль',
                min: 'введите минмум 6 символов',
            },
            confirmPassword: {
                label: 'Reintrodu parola',
                placeholder: 'Повторите пароль',
                required: 'повторите новый пароль',
                min: 'введите минмум 6 символов',
                match: 'введенные пароли не совпадают'
            },
            button: 'Salvează modificările',
            success: {
                title: 'Пароль успешно изменен',
                text: 'нажмите на кнопку чтобы продолжить',
                link: 'на главную'
            }
        }
    },
    history: {
        header: 'История',
        payOut: 'Cash out',
        payIn: 'Reîncărcare',
        status: {
            complete: 'Finalizat cu succes',
            wait: 'Te rugăm să aștepți',
            cancel: 'Respins'
        },
        showMoreLink: 'Mai mult',
        noMoreResults: 'Показаны все операции'
    },
    payIn: {
        header: 'Reîncărcare',
        sum: {
            label: 'Введите сумму пополнения',
        },
        button: 'Пополнить',
        error: {
            title: 'Произошла ошибка',
            text: 'Попробуйте позже',
            link: 'На главную'
        },
        success: {
            title: 'Пополнено!',
            text: 'Ваш баланс успешно пополнен',
            link: 'На главную'
        }
    },
    payOut: {
        header: 'Cash out',
        sum: {
            label: 'Introdu suma pentru cash out',
            positive: 'укажите положительную сумму',
            required: 'укажите сумму',
            max: 'сумма слишком велика'
        },
        cardNumber: {
            label: 'Introdu număr card',
            full: 'укажите номер карты полностью',
            required: 'укажите номер карты'
        },
        button: 'Вывести',
        wagerNotice: {
            header: 'Активный вейджер',
            notice: 'Пожалуйста, сделайте ставки на сумму {sum} с основного счета, чтобы разблокировать вывод.'
        },
        error: {
            title: 'Произошла ошибка',
            link: 'Назад',
            code: {
                internal: 'внутренняя ошибка',
                no_suitable_account: 'нет подходящего аккаунта',
                funds: 'недостаточно средств',
                invalid_card: 'не подходящий номер карты',
                wager: 'у вас не отыгран вейджер'
            },
            unknownError: 'неизвестная ошибка: {code}'
        },
        success: {
            title: 'Запрос отравлен',
            text: 'Ваш баланс успешно пополнен',
            link: 'На главную'
        }
    },
    licence: {
        header: 'Licență'
    },
    faq: {
        header: 'FAQ'
    },
    notFound: {
        title: 'Страница не найдена',
        link: 'На главную'
    },
    logout: {
        title: 'Ați ieșit din cont',
        text: 'Apasă aici ca să ajungi pe pagina principală',
        link: 'на главную',
    },
    smsForm: {
        title: 'Введите код из СМС',
        sent: 'Мы отправили СМС на номер',
        notDeliveredLink: 'Не пришёл код?',
        invalidCode: 'Неверный код!'
    },
    registration: {
        init: {
            title: 'Регистрация',
            text: 'Введите номер телефона на который мы отправим СМС с кодом подтверждения',
            tel: {
                placeholder: 'Телефон',
                required: 'Укажите номер целиком',
            },
            error: {
                used: 'Этот номер телефон уже используется!',
                recoverButton: 'Восстановить пароль'
            },
            button: 'Далее',
            hasAccount: 'Уже есть аккаунт?',
            loginLink: 'Войти',
        },
        complete: {
            title: 'Придумайте пароль',
            text: 'Пароль должен содержать не менее 6 символов!',
            password: {
                label: 'Придумайте пароль',
                placeholder: 'Придумайте пароль',
            },
            confirmPassword: {
                label: 'Повторите пароль',
                placeholder: 'Пароль',
            },
            error: {
                required: 'Пожалуйста, укажите пароль',
                min: 'Слишком короткий пароль, введите минимум 6 символов',
                match: 'Пароли не совпадают',
            },
            button: 'Далее'
        },
        completed: {
            title: 'Регистрация завершена',
            text: 'вы успешно зарегистрировались, нажмите на кнопку чтобы продолжить',
            link: 'на главную'
        }
    },
    recover: {
        request: {
            header: 'Восстановление пароля',
            text: 'Введите номер телефона на который мы отправим СМС с кодом подтверждения',
            tel: {
                placeholder: 'Телефон'
            },
            error: {
                notFound: 'Номер не найден',
                regButton: 'Зарегистрироваться'
            },
            button: 'Далее'
        },
        reset: {
            header: 'Придумайте новый пароль',
            password: {
                label: 'Придумайте пароль',
                placeholder: 'Придумайте пароль',
            },
            confirmPassword: {
                label: 'Повторите пароль',
                placeholder: 'Пароль',
            },
            error: {
                required: 'Пожалуйста, укажите пароль',
                min: 'Слишком короткий пароль, введите минимум 6 символов',
                match: 'Пароли не совпадают',
            },
            button: 'Далее'
        },
        completed: {
            title: 'Пароль успешно изменен',
            text: 'нажмите на кнопку чтобы продолжить',
            link: 'на главную'
        }
    }
}
