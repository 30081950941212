export default {
    "header": {
        "login": "Kirish",
        "balance": "Balans",
        "sign_in": "Ro'yxatdan o'tish"
    },
    "banners": {
        "firstDeposit": {
            "title": "Yangi o’yinchilarga bonus"
        },
        "wheel": {
            "title": "Fortune g’ildiragi"
        },
        "cashback": {
            "title": "Hisobingizga qaytaramiz",
            "friday": "Juma kuni"
        }
    },
    "index": {
        "getBonusButton": "Bonus olish",
        "winnersHeader": "Bugungi kunning qahramonlari",
        "showMore": "Boshqa",
        "gamesHeaderDefault": "O’yinlar",
        "providers": "Provayderlar",
        "searchPlaceholder": "Slot qidirish",
        "favorite": "Sevimlilar",
        "lastGames": 'Eng so\'nggi o\'yinlar',
        "lotoClub": "Lotto klub",
        "slots": "Slotlar",
        "roulette": "Ruletka",
        "minigames": "Mini o’yinlar",
        "allGames": "Barcha o’yinlar"
    },
    "menu": {
        "main": "Bosh sahifa",
        "promo": "Aktsiyalar",
        "profile": "Mening profilim",
        "history": "Tarix",
        "deposit": "To’ldirish",
        "payout": "Chiqarish",
        "logout": "Chiqish",
        "login": "Kirish",
        "entrance": "Kirish/Registratsiya",
        "licence": "Litsenziya",
        "faq": "FAQ"
    },
    "login": {
        "header": "Kirish",
        "phoneInput": {
            "label": "Telefon raqamingiz",
            "placeholder": "Telefon",
            "required": "Telefon raqamingizni to’liq kiriting"
        },
        "passwordInput": {
            "label": "Parolingiz",
            "placeholder": "Parol",
            "required": "Parolni kiriting"
        },
        "forgotPasswordLink": "Parolingizni unuttingizmi?",
        "invalidCredentials": "Kiritilgan parol notug’ri",
        "restorePasswordButton": "Parolni tiklash",
        "loginButton": "Kirish",
        "registerLinkHeading": "Profilingiz yo’qmi?",
        "registerLink": "Ro’yxatdan o’tish"
    },
    "account": {
        "title": {
            "real": "Asosiy",
            "main": "Asosiy",
            "bonus": "Bonusli"
        }
    },
    "profile": {
        "header": "Profil",
        "passwordForm": {
            "header": "Parolni almashtirish",
            "currentPassword": {
                "label": "Eski parolingizni kiriting",
                "placeholder": "Eski parol",
                "required": "Joriy parolni kiriting",
                "invalid": "Noto’g’ri joriy parol"
            },
            "newPassword": {
                "label": "Yangi parolingizni kiriting",
                "placeholder": "Yangi parol",
                "required": "Yangi parolingizni kiriting",
                "min": "Kamida sakkizta belgi"
            },
            "confirmPassword": {
                "label": "Parolni qaytaring",
                "placeholder": "Parolni qaytaring",
                "required": "Yangi parolni qaytaring",
                "min": "Kamida sakkizta belgi",
                "match": "Kiritilgan parollar bir xil emas"
            },
            "button": "O’zgarishlarni saqlash",
            "success": {
                "title": "Parol muvaffaqiyatli o’zgartirildi",
                "text": "Davom etish uchun tugmani bosing",
                "link": "Bosh sahifaga"
            }
        }
    },
    "history": {
        "header": "Tarix",
        "payOut": "Pul mablag’larini chiqarish",
        "payOutReturn": "Pul mablag’larini chiqarish bekor qilish",
        "payIn": "To’ldirish",
        "status": {
            "complete": "Muvaffaqiyatli",
            "wait": "Kutmoqda",
            "cancel": "Rad etildi"
        },
        "showMoreLink": "Boshqa",
        "noMoreResults": "Barcha operatsiyalar ko’rsatilgan"
    },
    "payIn": {
        "header": "To’ldirish",
        "sum": {
            "label": "To’ldirish miqdorini kiriting"
        },
        "button": "To’ldirish",
        "error": {
            "title": "Xatolik yuz berdi!",
            "text": "Keyinchalik harakat qilib ko’ring",
            "link": "Bosh sahifaga"
        },
        "success": {
            "title": "To’ldirildi!",
            "text": "Balansingiz muvaffaqiyatli to’ldirildi",
            "link": "Bosh sahifaga"
        }
    },
    "payOut": {
        "header": "Chiqarish",
        "sum": {
            "label": "Chiqish miqdorini kiriting",
            "positive": "Ijobiy miqdorni kiriting",
            "required": "Miqdorini belgilang",
            "max": "Miqdori juda katta"
        },
        "cardNumber": {
            "label": "Karta raqamini kiriting",
            "full": "Karta raqamani to’liq kiriting",
            "required": "Karta raqamini kiriting"
        },
        "button": "Chiqarish",
        "goBack": "Orqaga",
        "wagerNotice": {
            "header": "Faol veijer",
            "notice": "Iltimos, pulni yechib olish uchun asosiy hisobingizdan {sum} tiking."
        },
        "identificationNotice": {
            "header": "Akkaunt aniqlanmagan",
            "notice": "Iltimoz, telefon raqamingizni yoki e-mail manzilingizni tasdiqlang"
        },
        "error": {
            "title": "Xato",
            "link": "Orqaga",
            "code": {
                "internal": "Ichki xato",
                "no_suitable_account": "Tegishli akkaunt yo’q",
                "funds": "Yetarli mablag’ yo’q",
                "invalid_card": "Noto’gri karta raqami",
                "wager": "Sizning veijer o’ynalmagan"
            },
            "unknownError": "noma’lum xato: {code}"
        },
        "success": {
            "title": "Talab yuborildi",
            "text": "To’lov yuborildi",
            "link": "Bosh sahifaga"
        }
    },
    "licence": {
        "header": "Litsenziya"
    },
    "faq": {
        "header": "FAQ",
        "register": {
            "q": "Ro'yxatdan o'tish va kirish",
            "a": "Yuqori o'ng burchakdagi \"Ro'yxatdan o'tish\" tugmasini bosing, agar sizda allaqachon hisob qaydnomangiz bo'lsa, telefon raqamingizni va parolingizni kiriting. Hisob qaydnomasini ochish uchun siz ro'yxatdan o'tishingiz kerak va bu ko'p vaqtni talab qilmaydi yoki taqdim etilgan ijtimoiy tarmoqlardan biri orqali tizimga kirmaydi. Endi siz o'ynashingiz, yutib olishingiz va bonuslarni olishingiz mumkin!"
        },
        "who-can": {
            "q": "Kim kabinetni ochishi mumkin?",
            "a": "kabinetni faqat 18 yoshdan oshgan yoki ko'rsatilgan shaxs joylashgan mamlakat qonunlariga muvofiq qonuniy yoshga etgan shaxslar ochishi mumkin. Har bir o'yinchi faqat bitta kabinetga ega bo'lishi mumkin"
        },
        "find-game": {
            "q": "O'yinni qanday topish mumkin?",
            "a": "Asosiy sahifadagi \"Qidiruv\" dan foydalanib, o'yin nomini to'liq yoki birinchi bir nechta harflarni kiriting. O'yinlarni \"Provayder\" orqali ham filtrlash mumkin."
        },
        "deposit": {
            "q": "Qanday qilib to'ldirish kerak?",
            "a": "\"To'ldirish\" tugmasini bosing va to'ldirish uchun miqdorni kiriting. Ochilgan oynada karta raqamini, amal qilish muddatini va kartaning orqa tomonidagi uchtalig kodni kiriting. Shundan so'ng, \"To'lash\" tugmasini bosing. To'ldirish bir zumda amalga oshiriladi, bankning aybi bilan kamdan-kam holatlar bundan mustasno. Barcha savollar uchun chatga murojaat qiling."
        },
        "payout": {
            "q": "Qanday qilib olish mumkin?",
            "a": "\"Chiqarish\" tugmasini bosing va ochilgan oynada to'lovni olmoqchi bo'lgan karta raqamini va miqdorini belgilang. Tekshiruvdan so'ng to'lov kartaga tushadi. Barcha savollar uchun chatga murojaat qiling."
        },
        "min-sum": {
            "q": "Minimal depozit va to'lov miqdori qancha?",
            "a": "To'ldirishning minimal miqdori  700 so'm. Minimal to'lov miqdori  500 so'm."
        },
        "bonus": {
            "q": "Bonuslar va bonus hisoblari nima?",
            "a": "Bonuslar klubning ichki valyutasi bo'lib, u bizning muassasamiz tomonidan sovg'a sifatida hisobga olinadi va Bonus hisobida saqlanadi. Bonuslarni aksiyalarda ishtirok etish orqali olish mumkin. Bonus mablag'larini yechib olish uchun bonus summasini oynash kerak."
        },
        "wager": {
            "q": "\"Faol veijer\" bu nima?",
            "a": "Veijer - olingan sovrinni qaytarib olish uchun bajarilishi kerak bo'lgan shartlar to'plami. \"Qayta oynab olish\" - g'alaba yoki yutqazishdan qat'i nazar, o'yin davomida ma'lum miqdorda aylanishlar yoki garovlar qiling (g'altakning har bir aylanishi hisobga olinadi, bunda haqiqiy mablag'lar hisobdan chiqariladi). Har bir bonus va har bir omonat o'z garoviga ega (tikish nisbati). Bu pul tikish uchun pul tikish miqdorini olish uchun miqdor ko'paytiriladigan koeffitsientdir. Masalan, tikish x2. 1000 bonusni yechib olish uchun oʻyin davomida 2000 ga tikishingiz kerak. Kerakli pul tikish summasining hisoblagichi \"Chiqarish\" menyusida joylashgan."
        },
        "password": {
            "q": "Parolni unutdingizmi?",
            "a": "Parolni tiklash uchun \"Parolni unutdim\" dan foydalaning. Telefon raqamingizni kiriting, olingan SMS kodini kiriting va yangi parol yarating. Har qanday savol uchun chatga yozishingiz mumkin."
        }
    },
    "notFound": {
        "title": "Sahifa topilmadi",
        "link": "Bosh sahifaga"
    },
    "logout": {
        "title": "Akkauntingizdan chiqdingiz",
        "text": "Davom etish uchun tugmani bosing",
        "link": "Bosh sahifaga",
        "success": {
            "header": "O’yindan chiqtingiz",
            "text": "Bosing",
            "button": "Bosh sahifaga"
        }
    },
    "smsForm": {
        "title": "SMS-dan kodni kiriting",
        "sent": "SMS orqali kod junatildi",
        "notDeliveredLink": "Kod kelmadi?",
        "invalidCode": "To’to’gri kod!"
    },
    "registration": {
        "init": {
            "title": "Registratsiya",
            "text": "Tasdiqlash kodini sms orqali yuborish uchun telefon raqamingizni kiriting",
            "tel": {
                "placeholder": "Telefon",
                "required": "Telefon raqamingizni to’liq kiriting"
            },
            "error": {
                "used": "Ushbu telefon raqami ishlatilgan!",
                "recoverButton": "Parolni tiklash"
            },
            "button": "Keyingi",
            "hasAccount": "Akkauntingiz bormi?",
            "loginLink": "Kirish"
        },
        "complete": {
            "title": "Parolni ixtiro qiling",
            "text": "Parol kamida sakkizta belgidan iborat bo’lishi kerak!",
            "password": {
                "label": "Parolni ixtiro qiling",
                "placeholder": "Parolni ixtiro qiling"
            },
            "confirmPassword": {
                "label": "Parolni qaytaring",
                "placeholder": "Parol"
            },
            "error": {
                "required": "Iltimos. Parolni kiriting",
                "min": "Parol juda qisqa, kamida sakkizta belgini kiriting",
                "match": "Parollar bir xil emas"
            },
            "button": "Keyingi"
        },
        "completed": {
            "title": "Ro’yhatga olish",
            "text": "Muvaffaqiyatli ro’yxatdan o’tdingiz, davom etish uchun tugmani bosing",
            "link": "Bosh sahifaga"
        }
    },
    "recover": {
        "request": {
            "header": "Parolni tiklash",
            "text": "Tasdiqlash kodini sms orqali yuborish uchun telefon raqamingizni kiriting",
            "tel": {
                "placeholder": "Telefon"
            },
            "error": {
                "notFound": "Raqam topilmadi",
                "regButton": "Ro’yxatdan o’tish"
            },
            "button": "Keyingi"
        },
        "reset": {
            "header": "Yangi parol kiriting",
            "password": {
                "label": "Parolni ixtiro qiling",
                "placeholder": "Parolni ixtiro qiling"
            },
            "confirmPassword": {
                "label": "Parolni qaytaring",
                "placeholder": "Parol"
            },
            "error": {
                "required": "Iltimos. Parolni kiriting",
                "min": "Parol juda qisqa, kamida sakkizta belgini kiriting",
                "match": "Parollar bir xil emas"
            },
            "button": "Keyingi"
        },
        "completed": {
            "title": "Parol muvaffaqiyatli o’zgartirildi",
            "text": "Davom etish uchun tugmani bosing",
            "link": "Bosh sahifaga"
        }
    },
    "promo": {
        "cashback": {
            "title": "Klubning Ceshbaki",
            "body": "<p> Keshbek &mdash; yo'qotilgan mablag'larning ma'lum foizini qaytarish.</p> <p>Keshbek klubning doimgi o'yinchilariga beriladi va haftalik yo'qotishlarning 10% ga teng. Miqdorni hisoblashda faqat haqiqiy puldan yo'qolgan o'z mablag'lari hisobga olinadi. Bonus balansi hisob-kitobga kiritilmagan. Keshbek har juma kuni soat 18:00 da avtomatik tarzda o'tkaziladi.</p>"
        },
        "deposit": {
            "title": "Birinchi depozit bo'yicha 20% BONUS",
            "body": "<p>Ushbu taklifning bir qismi sifatida foydalanuvchi bonus &mdash; kiritilgan summasidan 20% oladi. Bonusni olish uchun zarur bo‘lgan minimal depozit miqdori 700 so‘m.</p><p>Agar bonus qo'llanilsa, o'yin summasi kiritilgan  mablag' va bonusning umumiy qiymatiga o'rnatiladi.</p> <p>Ya'ni, siz 10 000 so'm depozit qilsangiz, 2 000 bonus olasiz.</p>"
        },
        "wheel": {
            "title": "Fortuna g'ildiragi - fortunani aylantiring",
            "body": "<p>Ko'plab sovg'alarni yutib olishingiz mumkin bo'lgan qiziqarli mini-o'yin!</p><p> Fortuna g'ildiragi &mdash; bu bepul kunlik mini-o'yin bo'lib, unda siz bonus ballari, depozitni to'ldirishni ko'paytirish yoki Fortune klubidan yoqimli sovg'alarni yutib olishingiz mumkin.Wheel of Fortune Club sektorlarga bo'lingan va deyarli har birining o'z sovrini bor. Fortune g'ildiragini ishga tushirish uchun g'ildirakning o'rtasida joylashgan \"startni\" tugmasini bosing. O'yin natijasi tasodifiy sonlar generatori yordamida aniqlanadi. Kiyim-kechak sovg'alarini yetkazib berish uchun to'lov g'olibning hisobidan amalga oshiriladi</p>"
        }
    }
}
