export default {
    "header": {
        "login": "Увійти",
        "balance": "Баланс"
    },
    "banners": {
        "firstDeposit": {
            "title": "Бонус новим гравцям"
        },
        "wheel": {
            "title": "Колесо фортуни"
        },
        "cashback": {
            "title": "Повернемо на рахунок"
        }
    },
    "index": {
        "getBonusButton": "Отримати бонус",
        "winnersHeader": "Герої дня",
        "showMore": "Показати ще",
        "gamesHeaderDefault": "Ігри",
        "providers": "Провайдери",
        "searchPlaceholder": "Пошук слота",
        "favorite": "Вибране",
        "lastGames": 'Останні ігри',
        "lotoClub": "Лото клуб",
        "slots": "Слоти",
        "roulette": "Рулетка",
        "minigames": "Міні ігри",
        "allGames": "Усі ігри"
    },
    "menu": {
        "main": "Головна",
        "promo": "Акції",
        "profile": "Мій профіль",
        "history": "Історія",
        "deposit": "Поповнити",
        "payout": "Вивести",
        "logout": "Вийти",
        "login": "Увійти",
        "entrance": "Вхід/Реєстрація",
        "licence": "Ліцензія",
        "faq": "FAQ"
    },
    "login": {
        "header": "Вхід",
        "phoneInput": {
            "label": "Номер телефону",
            "placeholder": "Телефон",
            "required": "Вкажіть номер повністю"
        },
        "passwordInput": {
            "label": "Ваш пароль",
            "placeholder": "пароль",
            "required": "Вкажіть пароль"
        },
        "forgotPasswordLink": "Забули пароль?",
        "invalidCredentials": "Пароль введений не правильно",
        "restorePasswordButton": "Відновити пароль",
        "loginButton": "Увійти",
        "registerLinkHeading": "Немає профілю?",
        "registerLink": "Зареєструватись"
    },
    "account": {
        "title": {
            "real": "Основний",
            "main": "Основний",
            "bonus": "Бонусний"
        }
    },
    "profile": {
        "header": "Профіль",
        "passwordForm": {
            "header": "Зміна пароля",
            "currentPassword": {
                "label": "Введіть старий пароль",
                "placeholder": "Старий пароль",
                "required": "Вкажіть поточний пароль",
                "invalid": "Неправильний поточний пароль"
            },
            "newPassword": {
                "label": "Введіть новий пароль",
                "placeholder": "Новий пароль",
                "required": "Вкажіть новий пароль",
                "min": "Введіть мінімум 8 символів"
            },
            "confirmPassword": {
                "label": "Повторіть пароль",
                "placeholder": "Повторіть пароль",
                "required": "Повторіть новий пароль",
                "min": "Введіть мінімум 8 символів",
                "match": "Введені паролі не співпадають"
            },
            "button": "Зберегти зміни",
            "success": {
                "title": "Пароль успішно змінено",
                "text": "Натисніть на кнопку, щоб продовжити",
                "link": "На головну"
            }
        }
    },
    "history": {
        "header": "Історія",
        "payOut": "Виведення коштів",
        "payOutReturn": "Скасування виведення",
        "payIn": "Поповнення",
        "status": {
            "complete": "Успішно",
            "wait": "Чекає",
            "cancel": "Відхилено"
        },
        "showMoreLink": "Показати ще",
        "noMoreResults": "Показано всі операції"
    },
    "payIn": {
        "header": "Поповнення",
        "sum": {
            "label": "Введіть суму поповнення"
        },
        "button": "Поповнити",
        "error": {
            "title": "Виникла помилка!",
            "text": "Спробуйте пізніше",
            "link": "На головну"
        },
        "success": {
            "title": "Поповнено!",
            "text": "Ваш баланс успішно поповнений",
            "link": "На головну"
        }
    },
    "payOut": {
        "header": "Вивести",
        "sum": {
            "label": "Введіть суму виводу",
            "positive": "Вкажіть позитивну суму",
            "required": "Вкажіть суму",
            "max": "Сума надто велика"
        },
        "cardNumber": {
            "label": "Введіть номер картки",
            "full": "Вкажіть номер картки повністю",
            "required": "Вкажіть номер картки"
        },
        "button": "Вивести",
        "goBack": "Назад",
        "wagerNotice": {
            "header": "Активний вейджер",
            "notice": "Будь ласка, зробіть ставки на суму {sum} з основного рахунку, щоб розблокувати висновок."
        },
        "identificationNotice": {
            "header": "Обліковий запис не ідентифіковано",
            "notice": "Будь ласка, підтвердіть ваш телефон або електронну пошту."
        },
        "error": {
            "title": "Виникла помилка",
            "link": "Назад",
            "code": {
                "internal": "Внутрішня помилка",
                "no_suitable_account": "Немає відповідного облікового запису",
                "funds": "Недостатньо коштів",
                "invalid_card": "Невідповідний номер картки",
                "wager": "У вас не відігран вейджер"
            },
            "unknownError": "Невідома помилка: {code}"
        },
        "success": {
            "title": "Запит відправлено",
            "text": "Запит на виплату успішно надіслано",
            "link": "На головну"
        }
    },
    "licence": {
        "header": "Ліцензія"
    },
    "faq": {
        "header": "FAQ",
        "register": {
            "q": "Реєстрація та Вхід",
            "a": "Натисніть кнопку “Реєстрація” у верхньому правому кутку, введіть номер телефону та пароль якщо у вас вже є кабінет. Для відкриття кабінету потрібно пройти реєстрацію і це не триватиме багато часу або увійдіть через одну із представлених соціальних мереж. Тепер Ви можете грати, вигравати та отримувати бонуси!"
        },
        "who-can": {
            "q": "Хто може відчинити кабінет?",
            "a": "Кабінет можуть відкрити лише особи віком від 18 років або які досягли допустимого віку відповідно до законодавства країни, в якій перебуває зазначена особа. Кожен гравець може мати лише один кабінет"
        },
        "find-game": {
            "q": "Як знайти гру?",
            "a": "Використовуючи «Пошук» на головній сторінці, введіть назву гри повністю або кілька перших літер. Також ігри можна фільтрувати по «Провайдеру»."
        },
        "deposit": {
            "q": "Як поповнити?",
            "a": "Натисніть «Поповнити» та вкажіть суму поповнення. У вікні, введіть номер картки, термін дії і тризначний код зі зворотного боку картки. Після цього натисніть \"Оплатити\". Поповнення відбувається миттєво крім рідкісних випадків з вини банку. З будь-яких питань звертайтесь до чату."
        },
        "payout": {
            "q": "Як отримати?",
            "a": "Натисніть «Вивести» і у вікні вкажіть суму та номер картки, на яку хочете отримати виплату. Після перевірки оплата надійде на картку. З будь-яких питань звертайтесь до чату."
        },
        "min-sum": {
            "q": "Яка мінімальна сума поповнення та виплати?",
            "a": "Мінімальна сума поповнення – 700 гривень. Мінімальна сума виплати – 500 гривень."
        },
        "bonus": {
            "q": "Що таке Бонуси та Бонусний рахунок?",
            "a": "Бонуси - це внутрішня валюта клубу, яка нараховується як подарунок від нашого закладу та зберігається на Бонусному рахунку. Бонуси можна отримувати, беручи участь в акціях. Для виведення бонусних коштів суму бонусу необхідно відіграти."
        },
        "wager": {
            "q": "Що таке активний вейджер?",
            "a": "Вейджер — набір умов, яких необхідно дотримуватись для виведення отриманого призу. «Відіграти» — зробити прокручування або ставки на певну суму в процесі гри, незалежно від виграшу або програшу (вважається кожне обертання барабанів, при якому списуються реальні засоби). Кожен бонус та кожне поповнення рахунку має свій вейджер (коефіцієнт відіграшу). Це коефіцієнт, на який множиться сума для отримання суми ставок для відіграшу. Наприклад, вейджер х2. Щоб вивести бонус розміром 1000, у процесі гри потрібно зробити ставок на 2000. Лічильник суми необхідного відіграшу знаходиться в меню \"Вивести\"."
        },
        "password": {
            "q": "Забув пароль?",
            "a": "Для відновлення пароля використовуйте \"Забули пароль\". Введіть номер телефону, введіть отриманий SMS і придумайте новий пароль. З будь-яких питань Ви можете написати в чат."
        }
    },
    "notFound": {
        "title": "Сторінку не знайдено",
        "link": "На головну"
    },
    "logout": {
        "title": "Ви вийшли зі свого облікового запису",
        "text": "Натисніть на кнопку, щоб продовжити",
        "link": "На головну",
        "success": {
            "header": "Ви вийшли",
            "text": "Натисніть",
            "button": "На головну"
        }
    },
    "smsForm": {
        "title": "Введіть код із SMS",
        "sent": "Ми відправили SMS на номер",
        "notDeliveredLink": "Чи не прийшов код?",
        "invalidCode": "Невірний код!"
    },
    "registration": {
        "init": {
            "title": "Реєстрація",
            "text": "Введіть номер телефону, на який ми відправимо SMS з кодом підтвердження",
            "tel": {
                "placeholder": "Телефон",
                "required": "Вкажіть номер повністю"
            },
            "error": {
                "used": "Цей номер телефону вже використовується!",
                "recoverButton": "Відновити пароль"
            },
            "button": "Далі",
            "hasAccount": "Вже є обліковий запис?",
            "loginLink": "Увійти"
        },
        "complete": {
            "title": "Придумайте пароль",
            "text": "Пароль повинен містити щонайменше 8 символів!",
            "password": {
                "label": "Придумайте пароль",
                "placeholder": "Придумайте пароль"
            },
            "confirmPassword": {
                "label": "Повторіть пароль",
                "placeholder": "Пароль"
            },
            "error": {
                "required": "Будь ласка, вкажіть пароль",
                "min": "Надто короткий пароль, введіть мінімум 8 символів",
                "match": "Паролі не співпадають"
            },
            "button": "Далі"
        },
        "completed": {
            "title": "Реєстрація завершена",
            "text": "Ви успішно зареєструвалися, натисніть на кнопку, щоб продовжити",
            "link": "На головну"
        }
    },
    "recover": {
        "request": {
            "header": "Відновлення паролю",
            "text": "Введіть номер телефону, на який ми відправимо SMS з кодом підтвердження",
            "tel": {
                "placeholder": "Телефон"
            },
            "error": {
                "notFound": "Номер не знайдено",
                "regButton": "Зареєструватись"
            },
            "button": "Далі"
        },
        "reset": {
            "header": "Придумайте новий пароль",
            "password": {
                "label": "Придумайте пароль",
                "placeholder": "Придумайте пароль"
            },
            "confirmPassword": {
                "label": "Повторіть пароль",
                "placeholder": "Пароль"
            },
            "error": {
                "required": "Будь ласка, вкажіть пароль",
                "min": "Надто короткий пароль, введіть мінімум 8 символів",
                "match": "Паролі не співпадають"
            },
            "button": "Далі"
        },
        "completed": {
            "title": "Пароль успішно змінено",
            "text": "Натисніть на кнопку, щоб продовжити",
            "link": "На головну"
        }
    },
    "promo": {
        "cashback": {
            "title": "Клубний кешбек",
            "body": "<p>Кешбек &mdash; повернення певного відсотка від програних коштів.</p><p>Кешбек доступний постійним гравцям Клубу і дорівнює 10% від програшу за тиждень. При розрахунку суми враховуються лише програні власні кошти із реального балансу. Бонусний баланс у розрахунку не бере участі. Нарахування Кешбека відбувається автоматично щоп'ятниці о 18 годині.</p>"
        },
        "deposit": {
            "title": "БОНУС 20% на перший депозит",
            "body": "<p>У рамках цієї пропозиції користувач отримує бонус &mdash; 20% від суми депозиту. Мінімальна сума депозиту, необхідна для отримання бонусу – 700 гривень</p><p>У разі застосування бонусу, сума вейджера буде встановлена на сукупне значення внесених коштів та бонусу.</p><p>Тобто при внесенні 10 000 гривень ви отримаєте 2 000 бонусів.</p>"
        },
        "wheel": {
            "title": "Колесо фортуни - розкрути фортуну",
            "body": "<p>Захоплююча міні-гра, в якій ви можете виграти безліч різноманітних призів!</p><p>Колесо Фортуни &mdash; це безкоштовна щоденна міні-гра, в якій можна виграти бонусні бали, доповнення до поповнення депозиту або приємні призи від Клубу. Колесо Фортуни поділено на сектори, і майже кожен з них має свій приз. Для запуску Колеса Фортуни натисніть кнопку 'Старт', в центрі колеса. Результат гри визначається за допомогою генератора випадкових чисел. Оплата доставки речових призів здійснюється за рахунок переможця</p>"
        }
    },
}
